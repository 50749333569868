var render = function render(){
  var _vm$$auth$user, _vm$$auth, _vm$$auth2, _vm$$auth3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header",
    class: {
      'header--off': (_vm$$auth$user = _vm.$auth.user) === null || _vm$$auth$user === void 0 ? void 0 : _vm$$auth$user.is_first_login
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('nuxt-link', {
    staticClass: "header__logo click_logo",
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.trackMenu('CVing logo', 'image');
      }
    }
  }, [_c('img', {
    attrs: {
      "loading": "lazy",
      "src": require("assets/images/blue_logo.svg"),
      "width": "70",
      "height": "24",
      "title": "CVing Logo",
      "alt": "CVing Logo"
    }
  })])]), _vm._v(" "), _c('nav', {
    staticClass: "navigation"
  }, [(_vm$$auth = _vm.$auth) !== null && _vm$$auth !== void 0 && _vm$$auth.loggedIn ? _c('div', {
    staticClass: "profile_link",
    domProps: {
      "textContent": _vm._s(_vm.getUserName)
    },
    on: {
      "click": function ($event) {
        return _vm.goToPersonalArea();
      }
    }
  }) : _vm._e(), _vm._v(" "), !_vm.$auth || !((_vm$$auth2 = _vm.$auth) !== null && _vm$$auth2 !== void 0 && _vm$$auth2.loggedIn) ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath('login')
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        _vm.trackMenu(_vm.$t('menu.login').toString(), 'button');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('menu.login')))])]) : _vm._e(), _vm._v(" "), !_vm.$auth || !((_vm$$auth3 = _vm.$auth) !== null && _vm$$auth3 !== void 0 && _vm$$auth3.loggedIn) ? _c('span', {
    staticClass: "navigation-slash"
  }) : _vm._e(), _vm._v(" "), !_vm.$auth || !_vm.$auth.loggedIn ? _c('span', {
    staticClass: "navigation__external",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.redirectToPlatform
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        _vm.trackMenu(_vm.$t('menu.forCompanies').toString(), 'button');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('menu.forCompanies')) + " ")])]) : _vm._e(), _vm._v(" "), _c('svg', {
    staticStyle: {
      "cursor": "pointer!important"
    },
    attrs: {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    },
    on: {
      "click": function ($event) {
        _vm.isMenuOpen = !_vm.isMenuOpen;
      }
    }
  }, [_c('g', {
    attrs: {
      "id": "menu"
    }
  }, [_c('g', {
    attrs: {
      "id": "bars"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector",
      "d": "M23.8401 16.7736H6.34009C6.09145 16.7736 5.85299 16.6748 5.67718 16.499C5.50136 16.3232 5.40259 16.0847 5.40259 15.8361C5.40259 15.5874 5.50136 15.349 5.67718 15.1731C5.85299 14.9973 6.09145 14.8986 6.34009 14.8986H23.8401C24.0887 14.8986 24.3272 14.9973 24.503 15.1731C24.6788 15.349 24.7776 15.5874 24.7776 15.8361C24.7776 16.0847 24.6788 16.3232 24.503 16.499C24.3272 16.6748 24.0887 16.7736 23.8401 16.7736Z",
      "fill": "#003263"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "Vector_2",
      "d": "M23.8401 11.1486H6.34009C6.09145 11.1486 5.85299 11.0498 5.67718 10.874C5.50136 10.6982 5.40259 10.4597 5.40259 10.2111C5.40259 9.96242 5.50136 9.72396 5.67718 9.54815C5.85299 9.37233 6.09145 9.27356 6.34009 9.27356H23.8401C24.0887 9.27356 24.3272 9.37233 24.503 9.54815C24.6788 9.72396 24.7776 9.96242 24.7776 10.2111C24.7776 10.4597 24.6788 10.6982 24.503 10.874C24.3272 11.0498 24.0887 11.1486 23.8401 11.1486Z",
      "fill": "#003263"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "id": "Vector_3",
      "d": "M23.8401 22.3986H6.34009C6.09145 22.3986 5.85299 22.2998 5.67718 22.124C5.50136 21.9482 5.40259 21.7097 5.40259 21.4611C5.40259 21.2124 5.50136 20.974 5.67718 20.7981C5.85299 20.6223 6.09145 20.5236 6.34009 20.5236H23.8401C24.0887 20.5236 24.3272 20.6223 24.503 20.7981C24.6788 20.974 24.7776 21.2124 24.7776 21.4611C24.7776 21.7097 24.6788 21.9482 24.503 22.124C24.3272 22.2998 24.0887 22.3986 23.8401 22.3986Z",
      "fill": "#003263"
    }
  })])])])], 1)], 1), _vm._v(" "), _c('Menu', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        _vm.isMenuOpen = false;
      },
      expression: "()=>{isMenuOpen = false}"
    }],
    attrs: {
      "is-menu-open": _vm.isMenuOpen
    },
    on: {
      "closeMenu": function ($event) {
        _vm.isMenuOpen = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }