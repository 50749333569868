var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('section', {
    staticClass: "footer-text-links"
  }, _vm._l(_vm.textLinks, function (link, index) {
    return _c('span', {
      key: 'link_' + link.name + '_' + index,
      staticClass: "link"
    }, [link.link ? _c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.handleLinkClick(link);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(link.name))])]) : _c('a', {
      attrs: {
        "href": "javascript: Cookiebot.renew()"
      }
    }, [_c('span', [_vm._v(_vm._s(link.name))])])]);
  }), 0), _vm._v(" "), _c('section', {
    staticClass: "divider"
  }, [_vm._v("\n    |\n  ")]), _vm._v(" "), _c('section', {
    staticClass: "footer-copyright"
  }, [_vm._v("\n    Copyright " + _vm._s(_vm.$dayjs().year()) + " © CVing S.r.l. | P. IVA 04681350270 |\n    REA MI - 2515112 | Aut. Def. ANPAL n. 0000142 del 28/11/2023\n  ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }