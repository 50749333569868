export default {
  SET_CAMPAIGN: (state, payload) => {
    state.campaign = payload
  },

  SET_INTERVIEW_ID: (state, payload) => {
    state.interviewId = payload
  },

  SET_UPDATE_CV: (state, payload) => {
    state.updateCv = payload
  }
}
