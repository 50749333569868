export default {
  setCampaign: ({ commit }, payload) => {
    commit('SET_CAMPAIGN', payload)
  },

  setInterviewId: ({ commit }, payload) => {
    commit('SET_INTERVIEW_ID', payload)
  },

  setUpdateCv: ({ commit }, payload) => {
    commit('SET_UPDATE_CV', payload)
  }
}
