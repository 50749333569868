export default function ({ route, redirect, app, $auth, store }) {
  if (route && route.params && route.params.id) {
    store.dispatch('campaign/setInterviewId', route.params.id)
  }
  // console.log('MIDDLEWARE FIRSTLOGIN')
  // console.log(store.state.notifications.isFirstLogin)
  // console.log($auth.user)
  if ($auth.loggedIn && route.name !== null && !(route.name.startsWith('user-settings-password')) && $auth.user.is_first_login) {
    return redirect(app.localePath('user-settings-password'))
  }
}
